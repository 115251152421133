@font-face {
    font-family: 'Retron';
    src: local('Retron'), url(./fonts/Retron2000.ttf) format('truetype');
}

@font-face {
    font-family: 'Neue Montreal';
    src: local('Neue Montreal'), url(./fonts/NeueMontreal.otf) format('opentype');
}

@font-face {
    font-family: 'Messina Sans';
    src: local('Messina Sans'), url(./fonts/MessinaSans.ttf) format('truetype');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Retron';
    outline: none;
}

$screenMin: 500px;
$borderThin: 1px solid rgba(255, 255, 255, 0.453);
$borderLight: 1px solid rgba(255, 255, 255, 1);
$borderBlack: 1px solid #000;
$borderHighlight: 1px solid #FFFF00 !important;

html,
body,
#root,
.wrapper {
    height: 100vh;
    width: 100%;
    background-color: #000;
    border: none;
    overflow: hidden;
    z-index: -99999999;
}

// Helper classes
.small-text {
    font-size: 0.5em;
}

.text-muted {
    color: rgb(158, 158, 158) !important;
}

.highlight-text {
    color: #FFFF00 !important;
}

.highlight-border {
    border-color: #FFFF00 !important;
}

.height-1-3 {
    height: calc(100% / 3) !important;
}

.height-2-3 {
    height: calc(100% / 3 * 2) !important;
}

.disabled-button {
    cursor: default !important;
}

.pointer {
    cursor: pointer;
}

.background-tint {
    background-color: rgba(0, 0, 0, 0.613);
}

.definition {
    font-family: 'Serif' !important;
    font-style: italic;
}

.profile-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}


/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
}

.template-wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.template {
    width: 100%;
    height: 100%;
    z-index: 999;
    position: relative;

    display: grid;
    grid-template-columns: [colstart] 1fr [col1] 1fr [colend];
    grid-template-rows: [rowstart] 15% [row1] 10% [row2] 1fr [row3] 7.5% [rowend];
}

.title {
    grid-row: rowstart / row1;
    grid-column: colstart / col1;
    color: #fff;
    font-size: 8px;
    letter-spacing: 1px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    & div * {
        display: inline-block;
        margin: 0px 5px;
    }
}

.image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    & .image-gradient {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgb(0, 0, 0);
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 56%);
    }

    & img {
        min-height: 100%;
        width: 100%;
        z-index: -1;
        object-fit: cover;
    }
}

.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    cursor: pointer;

    & h1 {
        font-family: 'Messina Sans';
        color: inherit;
        letter-spacing: 1px;
        font-size: 0.9em;
    }

    &:hover {
        background: #FFFF00;

        & h1 {
            color: #000 !important;
        }
    }
}

// Specifically for button instructions
.button-disabled-left {
    z-index: -1;
    border-top: $borderThin;
    border-right: $borderThin;
    border-left: none;
}

.button-disabled-middle {
    z-index: -1;
    border-top: $borderThin;
}

.button-disabled-right {
    z-index: -1;
    border-top: $borderThin;
    border-left: $borderThin;
}

.button-active-left {
    z-index: 1;
    border-top: $borderLight;
    border-right: $borderLight;
}

.button-active-middle {
    z-index: 1;
    border-left: $borderLight;
    border-top: $borderLight;
    border-right: $borderLight;
}

.button-active-right {
    z-index: 1;
    border-left: $borderLight;
    border-top: $borderLight;
}

.button-pointer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);

    & .button-pointer-text {
        margin-bottom: 15px;
        font-family: 'Messina Sans';
        white-space: nowrap;
    }
}

.button-pointer-left {
    left: calc(100% / 3 / 2);
    bottom: 8%;
}

.button-pointer-middle {
    left: 50%;
    bottom: 8%;
}

.button-pointer-right {
    left: calc(100% / 3 / 2 * 5);
    bottom: 8%;
}

.button-group {
    grid-column: colstart / colend;
    grid-row: row3 / rowend;
    display: flex;
    justify-content: space-evenly;
}

.button-group-borders {
    border-top: $borderThin;

    & .button:not(:first-child) {
        border-left: $borderThin;
    }
}

.info {
    grid-row: row1 / row3;
    grid-column: colstart / colend;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0px 25px;
    height: calc(100% - 25px);
    overflow-y: hidden;
    overflow-x: hidden;

    & .info-name {
        font-family: 'Messina Sans';
        color: #fff;
        margin-bottom: 15px;
        font-size: 2.5em;
        letter-spacing: 1px;
    }
}

.results-table {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    & .results-table-title {
        text-align: center;
        margin: 50px;

        & h1 {
            font-family: 'Messina Sans';
            font-size: 1em;
            color: #fff;
        }
    }

    & .results-table-item-highlight {
        color: #FFFF00 !important;
    }

    & .results-table-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: $borderThin;
        padding-bottom: 10px;
        margin-bottom: 12px;
        color: #fff;

        & .results-table-item-name {
            font-family: 'Messina Sans';
            font-size: 0.8em;
        }
    }
}

// .button-pointer.button-pointer-middle.noselect {

//     &:not(.final) {
//         .button-pointer-text.highlight-text.glow {
//             -webkit-animation: glow 1s linear infinite alternate;
//             -moz-animation: glow 1s linear infinite alternate;
//             animation: glow 1s linear infinite alternate;
//         }
//     }

//     &.final {
//         .button-pointer-text.highlight-text.glow {
//             -webkit-animation: glowFinal 1s linear infinite alternate;
//             -moz-animation: glowFinal 1s linear infinite alternate;
//             animation: glowFinal 1s linear infinite alternate;
//             color: black!important;
//         }
//     }
// }



// @keyframes glow {
//     from {
//         text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff;
//     }

//     to {
//         text-shadow: 0 0 20px black, 0 0 30px black, 0 0 40px black, 0 0 50px black, 0 0 60px black, 0 0 70px black, 0 0 80px black;
//     }
// }

// @keyframes glowFinal {
//     from {
//         text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff;
//     }

//     to {
//         text-shadow: 0 0 20px black, 0 0 30px black, 0 0 40px black, 0 0 50px black, 0 0 60px black, 0 0 70px black;
//     }
// }

.fade-in {
    -webkit-animation: fadeIn 0.5s ease-in forwards;
    -moz-animation: fadeIn 0.5s ease-in forwards;
    animation: fadeIn 0.5s ease-in forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1 !important; }
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31px;

    .rangeslider {
        width: 125px;
        margin-right: 15px;
        height: 2px;

        .rangeslider__fill {
            background-color: yellow;
        }

        &.rangeslider-horizontal .rangeslider__handle {
            transform: translate3d(-50%, -80%, 0);
        }

        &.rangeslider-horizontal .rangeslider__handle:after {
            display: none;
        }

        .rangeslider__handle {
            background: transparent;
            border: transparent;
            width: 16px;
            height: 16px;
            top: 6px;
            left: 6px;
            border-radius: 50%;
            background-color: #dadada;
            box-shadow: 0 1px 3px rgb(0, 0, 0 / 40%) inset, 0 -1px 3px rgb(0, 0, 0 / 40%) inset;
        }
    }
}

.login-form {
    grid-column: colstart / colend;
    grid-row: rowstart / rowend;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 25px;
    text-align: center;
    z-index: 2;

    h1 {
        margin-bottom: 15px;
        font-family: 'Messina Sans';
        font-size: 25px;
    }

    .twitter-login {
        margin-bottom: 10px;

        a, button {
            background-color: transparent;
            border: 1px solid black;
            padding: 9px 10px 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    >div {
        display: flex;
        flex-direction: column;



        span {
            font-family: 'Messina Sans';
            font-weight: bold;
        }

        input {
            background-color: black;
            color: yellow;
            border: 2px solid black;
            outline: none;
            padding: 10px;
            margin-top: 5px;
            margin-bottom: 15px;
            border-radius: 5px;
            font-family: 'Messina Sans';
            width: 250px;

            &.failed {
                border-color: rgb(255, 51, 51);
            }
        }
    }
}

@media (min-width: 500px) {
    .login-form {
        grid-column: colstart / col1;
        grid-row: row1 / rowend;
        display: flex;
        align-items: center;
        height: 100%;
        text-align: left;
    }
}

.results-table-footer {
    color: #fff;
    margin-top: 25px;
    font-size: 1em;
    font-family: 'Messina Sans';
    text-align: center;

    & span {
        font-size: 1em;
        font-family: 'Messina Sans';
        border-bottom: $borderThin;
        letter-spacing: 1px;
    }
}

.leaderboard {
    grid-column: colstart / colend;
    grid-row: row1 / row4;
    padding: 0px 25px;
    height: 100%;
    overflow-y: hidden;

    & .leaderboard-header {
        display: flex;
        height: 60px;
        border-bottom: $borderThin;
        border-top: $borderThin;

        & .leaderboard-header-category:not(:first-child) {
            border-left: $borderThin;
        }

        & .leaderboard-header-category {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: lightgray;
            cursor: pointer;

            & h1 {
                font-size: 0.8em;
                letter-spacing: 1px;
                font-family: 'Messina Sans';
                color: inherit;
            }
        }
    }

    & .leaderboard-list {
        display: flex;
        flex-direction: column;
        height: calc(100% - 120px);
        overflow-y: overlay;

        & .leaderboard-list-item {
            height: 70px;
            border-bottom: 1px solid rgba(204, 204, 204, 0.289);
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            & .leaderboard-list-item-left {
                display: flex;
                align-items: center;
                height: 100%;

                & .leaderboard-list-item-left-index {
                    color: #FFFF00;
                    font-size: 1.2em;
                }

                & .leaderboard-list-item-left-name {
                    margin-left: 25px;
                    color: #fff;
                    font-family: 'Messina Sans';
                    font-size: 1.2em;
                }
            }

            & .leaderboard-list-item-right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: 25px;

                & img {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }

    }

    & .leaderboard-panel {
        align-items: center;
        border-top: 1px solid rgba(255, 255, 255, 0.453);
        display: flex;
        height: 60px;
        justify-content: center;

        button {
            width: 100px;
            padding: 5px 5px;
            transition-duration: .3s;
            outline: none;
            font-family: 'Messina Sans';
            font-weight: 600;
            cursor: pointer;

            &:nth-of-type(1) {
                margin: 0px auto 0px 0px;

                &:hover,
                &:focus,
                &:active {
                    border-color: #FFFF00;
                    color: #FFFF00;
                }
            }

            &:nth-of-type(2) {
                background-color: #FFFF00;
                color: black;
                border: 1px solid #FFFF00;

                &:hover,
                &:focus,
                &:active {
                    background-color: transparent;
                    color: #FFFF00;
                }
            }

            &:nth-of-type(3) {
                margin: 0px 0px 0px auto;

                &:hover,
                &:focus,
                &:active {
                    border-color: rgb(192, 24, 24);
                    color: rgb(192, 24, 24);
                }
            }

            &:not(:nth-of-type(2)) {
                background-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.453);
                color: lightgray;

            }

        }
    }
}

.back-to-game {
    grid-column: colstart / col1;
    grid-row: rowstart / row1;

    display: flex;
    justify-content: flex-end;

    a {
        font-family: 'Messina Sans';
        color: white;
        text-decoration: none;
        margin-right: 30px;
        margin-top: 25px;
        height: 30px;
        transition-duration: .3s;

        &:hover {
            text-decoration: underline;
            color: yellow;
        }
    }
}

.instruction-text {
    grid-column: colstart / colend;
    grid-row: rowstart / rowend;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Messina Sans';
    padding: 0px 35px;
    text-align: center;

    & * {
        letter-spacing: 1px;
        font-family: inherit;
        margin: 15px 0px;
        width: 100%;
    }

    & .instruction-text-header {
        font-size: 1.2em;
    }

    & .instruction-text-body {
        font-size: 2em;
    }

    & .instruction-text-footer {
        font-size: 1.1em;
    }

}

.music {
    grid-column: colstart / colend;
    grid-row: rowstart / rowend;
    margin: 25px;
    display: flex;
    justify-content: flex-end;
    z-index: 1;

    & > div {
        height: 0;
    }

    .music-btn {
        cursor: pointer;
    }

    .sign-in-button {
        color: white;
        margin-top: 15px;
        display: none;
        align-items: flex-end;
        justify-content: flex-end;
        
        a, button {
            font-family: 'Messina Sans';
            font-weight: bold;
            letter-spacing: 1px;
            cursor: pointer;
            color: white;
            text-decoration: none;
            background-color: transparent;
            border: none;
        }

        a:hover {
            text-decoration: underline;
        }

        &.active {
            display: flex;
        }
    }
}

.audio-player {
    display: none;
}

.leaderboard-title {
    display: none;
}

.prompt-text {
    grid-column: colstart / colend;
    grid-row: rowstart / rowend;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 25px;
    text-align: center;

    & h1 {
        color: #000;
        font-family: 'Messina Sans';
    }
}

.yellow-buttons {
    & div.button {
        border: none;
        border-bottom: none !important;
    }
    & div.button:not(:first-child) {
        border-left: 1px solid #000;
    }
}

@media(min-width: 500px) {

    .yellow-buttons {
        & div.button {
            border: none;
            border-left: none !important;
        }
        & div.button:not(:last-child) {
            border-bottom: 1px solid #000 !important;
        }
    }

    .template {
        margin: 25px;
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: $borderThin;

        grid-template-rows: [rowstart] 15% [row1] auto [rowend];
        grid-template-columns: [colstart] 1fr [col1] 1fr [colend];
    }

    .template-border-highlight {
        border: $borderHighlight;
    }

    .template-border-none {
        border: none;
    }

    .template-border-black {
        border: $borderBlack;
    }

    .title {
        grid-row: rowstart / row1;
        grid-column: colstart / col1;
        font-size: 0.4em;
        max-height: calc(100% - 25px);
        align-items: flex-start;
        justify-content: unset;
        margin-left: 25px;
        margin-top: 25px;
    }

    .image {
        height: 100%;

        & .image-gradient {
            display: block;
        }

        & img {
            width: 50%;
        }
    }

    .button-group {
        grid-row: rowstart / rowend;
        grid-column: col1 / colend;
        flex-direction: column;
        border-top: none;
        height: 100%;
        border-left: $borderThin;
        z-index: 1;

        & .button,
        div.upload {
            font-size: 2.5em;
            transition-duration: .3s;
        }

        div.upload {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #fff;

            textarea {
                border: 1px solid yellow;
                background-color: transparent;
                border-radius: 5px;
                width: 100%;
                max-width: 100%;
                margin-top: 10px;
                font-family: 'Messina Sans';
                padding: 8px;
                font-size: 12px;
                color: #fff;
            }

            .file-upload, .profile-upload-submit {
                width: 80%;
            }

            span {
                font-weight: bold;
                font-size: 18px;
            }

            >div {
                display: flex;
                flex-direction: column;
                width: 90%;
                margin-bottom: 30px;

                input {
                    margin-top: 15px;
                    background-color: transparent;
                    color: white;
                    font-weight: bold;
                    border: 1px solid yellow;
                    border-radius: 5px;
                    padding: 10px;
                    outline: none;
                }
            }

            p {
                display: none;
                height: 30px;
                font-size: 12px;
                text-align: start;
                width: 250px;


                &.success {
                    display: initial;
                    color: green;
                }

                &.failed {
                    color: red;
                    display: initial;
                }
            }

            button {
                margin-top: 30px;
                width: 250px;
                background-color: transparent;
                color: yellow;
                border: 1px solid yellow;
                border-radius: 5px;
                padding: 10px;
                font-weight: bold;
                transition-duration: .3s;
                cursor: pointer;

                &:hover {
                    background-color: yellow;
                    color: black;
                }
            }

            span,
            input,
            button {
                font-family: 'Messina Sans';
            }
        }

        & .button:not(:first-child),
        div.upload {
            border-left: none;
            border-top: $borderThin;
        }
    }

    .info {
        grid-row: row1 / rowend;
        grid-column: colstart / col1;
        display: flex;

        & p {
            color: #fff;
            font-family: 'Messina Sans';
        }
    }

    .leaderboard-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        grid-row: row1 / rowend;
        grid-column: colstart / col1;
        margin: 0px 25px;

        & h1 {
            color: #fff;
            font-size: 2.8em;
            font-family: 'Messina Sans';
        }

        & footer {
            text-align: left;
            margin-bottom: 25px;
        }
    }

    .leaderboard {
        grid-column: col1 / colend;
        grid-row: rowstart / rowend;
        border-left: $borderThin;
        padding: 0px;

        & .leaderboard-header {
            border-top: none;
        }

        & .leaderboard-list {
            & .leaderboard-list-item {

                & .leaderboard-list-item-center {
                    grid-column: span 2;
                    width: 100%;
                    color: #eee;
                    font-family: 'Messina Sans';
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & span {
                        margin-right: 15px;
                        font-family: inherit;
                        transition: color 0.3s;
                    }

                    & span:hover {
                        color: yellow;
                    }
                }

                & .leaderboard-list-item-left {
                    margin-left: 25px;

                    & .leaderboard-list-item-left-name {
                        margin-left: 10px;
                        position: relative;
                        top: 2px;
                    }
                }

                & .leaderboard-list-item-right {
                    margin-right: 25px;
                }
            }
        }

        & .leaderboard-panel {
            button {
                min-width: 150px;

                &:nth-of-type(1) {
                    margin-left: 15px;
                }

                &:nth-of-type(3) {
                    margin-right: 15px;
                }
            }
        }
    }

    .desktop-left-button-group {
        grid-row: row1 / rowend;
        grid-column: colstart / col1;
        border-left: none;
        align-self: flex-end;
        border-top: $borderThin;
        display: flex;
        flex-direction: row;
        
        & .button { border-top: none; }
        & .button:not(:first-child) { border-left: $borderThin; border-top: none; }
    }

    .desktop-height-1-3 {
        height: calc(100% / 3);
    }

    .desktop-height-2-3 {
        height: calc(100% / 3 * 2);
    }

    .instruction-text {
        grid-column: colstart / col1;
        grid-row: rowstart / rowend;
        text-align: left;
        justify-content: unset;
        flex-direction: unset;

        & span {
            width: 75%;
        }
    }

    .instruction-text-desktop-outline-highlight {
        border-top: $borderHighlight;
        border-left: $borderHighlight;
        border-bottom: $borderHighlight;
    }

    // Specifically for button instructions
    .button-disabled-left {
        z-index: -1;
        border-left: $borderHighlight;
        border-top: $borderThin;
        border-right: $borderThin;
    }

    .button-disabled-middle {
        z-index: -1;
        border-left: $borderHighlight;
        border-top: none;
        border-bottom: none;
        border-right: $borderThin;
    }

    .button-disabled-right {
        z-index: -1;
        border-left: $borderHighlight;
        border-top: none;
        border-bottom: $borderThin;
        border-right: $borderThin;
    }

    .button-active-left {
        z-index: 1;
        border: $borderLight !important;
    }

    .button-active-middle {
        z-index: 1;
        border: $borderLight !important;
    }

    .button-active-right {
        z-index: 1;
        border: $borderLight !important;
    }

    .button-pointer {
        flex-direction: row;
        align-items: center;
        transform: translateY(-50%) translateX(-120%);

        & .button-pointer-text {
            margin: 0px 15px;
        }

        & svg {
            transform: rotate(-90deg);
        }
    }

    .button-pointer-left {
        left: 50%;
        top: calc(100% / 3 / 2);
    }

    .button-pointer-middle {
        left: 50%;
        top: 50%;
    }

    .button-pointer-right {
        left: 50%;
        top: calc(100% / 3 / 2 * 5);
    }

    .music {
        grid-column: colstart / col1;
    }

    .prompt-text {
        grid-column: colstart / col1;
        grid-row: row1 / rowend;
        display: flex;
        align-items: flex-end;
        height: 100%;
        text-align: left;

        & h1 {
            margin: 35px 0px;
        }
    }

    .results-table-custom-desktop {
        display: flex;
        flex-direction: column;
        margin: 0px;
        height: calc(100% - 60px);

        & .results-table-item {
            margin: 0px;
            padding: 0px 30px;
            height: calc(100% / 3);
            align-items: center;
            border: none;

            & .results-table-item-percent {
                font-size: 2.5em;
            }
        }

        & .results-table-item {
            border-bottom: 1px solid rgba(204, 204, 204, 0.164);
        }

        & .results-table-item:nth-child(4) {
            border-bottom: none;
        }

        & .results-table-title,
        & .results-table-footer {
            display: none;
        }
    }

}