.leaderboard .leaderboard-list .leaderboard-list-item {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .trash {
    button {
      cursor: pointer;
      background-image: none;
      background-color: transparent;
      outline: none;
      border: none;
      
      img {
        margin-left: 15px;
        width: 25px;
        height: auto;
      }
    }
  }
  
  .edit-profile-btn {
    margin-left: 5px;
    cursor: pointer;
  }

  .remove-element {
    pointer-events: all;

    button {
      cursor: pointer;
      background-image: none;
      background-color: transparent;
      outline: none;
      border: none;
      margin-top: 7px;
    }
  }
}