.edit-profile {
    width: 80%;
    margin: 50px auto;
}

.back-btn p {
    color: #fff;
    font-family: 'Messina Sans';
    transition: 0.2s all;
    display: inline-block;
    cursor: pointer;

    &:hover {
        color: #ccc;
    }
}

.edit-profile-body {
    margin-top: 15px;

    & .edit-profile-body-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & input {
            color: #fff;
            font-family: 'Messina Sans';
            font-size: 1em;
            background-color: transparent;
            border: 1px solid yellow;
            border-radius: 5px;
            padding: 15px 10px;
        }
    
        & img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    & textarea {
        margin-top: 15px;
        border: 1px solid yellow;
        border-radius: 5px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        background-color: transparent;
        color: #fff;
        font-family: 'Messina Sans';
        padding: 15px;
    }

    & button {
        width: 100%;
        font-family: 'Messina Sans';
        background-color: transparent;
        border: 1px solid yellow;
        border-radius: 3px;
        margin-top: 15px;
        color: #fff;
        padding: 1em 1em;
        cursor: pointer;
        transition: 0.2 all;

        &:hover {
            color: #ccc;
        }
    }

}

.success-alert, .error-alert {
    text-align: center;
    margin-top: 15px;
}

.success-alert { color: green; }
.error-alert { color: red; }